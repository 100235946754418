let key = {};
let Envname = "demo";
if (Envname == "demo") {
  const API_URL = "https://sandboxnftapi.wealwindemo.com"; //DEMO
  //const API_URL = "https://api.demo.biz"; //Live
  const CollContract = "0x3E3fc78Fd27c7116A8378e2221F90EdEc6FAD3A7";
  const SingleNFT = "0x3E3fc78Fd27c7116A8378e2221F90EdEc6FAD3A7";
  const tokenContract = "0xda25017e14fb85301005612fd5fef77bd5ea4cd0";
  const swapContract = "0x3e956175f93243da4bc8b8bdd99ce1a9dce48c9b";
  const tokenSymbol = "WMATIC";
  const networkVersion = 97;
  const IPFS_IMG = "https://ipfs.io/ipfs";
  const adminAddress = "0x80Aa595c83430288E3A75F4D03bE15b72385420F";
  const scan = "https://mumbai.polygonscan.com/tx";
  key = {
    baseUrl: `${API_URL}`,
    CollContract: CollContract,
    tokenSymbol: tokenSymbol,
    SingleNFT: SingleNFT,
    tokenContract: tokenContract,
    networkVersion: networkVersion,
    IPFS_IMG: IPFS_IMG,
    adminAddress: adminAddress,
    swapContract: swapContract,
    scan: scan
  };
} else {
  const API_URL = "http://localhost";
  const CollContract = "0x3E3fc78Fd27c7116A8378e2221F90EdEc6FAD3A7";

  const SingleNFT = "0x3E3fc78Fd27c7116A8378e2221F90EdEc6FAD3A7";

  const tokenContract = "0xda25017e14fb85301005612fd5fef77bd5ea4cd0";
  const swapContract = "0x3e956175f93243da4bc8b8bdd99ce1a9dce48c9b";
  const tokenSymbol = "WMATIC";
  const networkVersion = 97;
  const IPFS_IMG = "https://ipfs.io/ipfs";

  const adminAddress = "0x80Aa595c83430288E3A75F4D03bE15b72385420F";
  const scan = "https://bsc-testnet-rpc.publicnode.com";

  key = {
    baseUrl: `${API_URL}:3040`,
    CollContract: CollContract,
    tokenSymbol: tokenSymbol,
    SingleNFT: SingleNFT,
    tokenContract: tokenContract,
    networkVersion: networkVersion,
    IPFS_IMG: IPFS_IMG,
    adminAddress: adminAddress,
    swapContract: swapContract,
    scan: scan
  };
}

export default key;
