import axios from "./axios";
import setAuthToken from "../lib/setAuthToken";
import config from "../lib/config";
import { decodeJwt } from "./jsonWebToken";

export const login = async (data, dispatch) => {
  try {
    // // console.log(data,'dataa')
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/login`,
      data,
    });

    localStorage.setItem("admin_token", respData.data.token);
    setAuthToken(respData.data.token);
    decodeJwt(respData.data.token, dispatch);

    return {
      loading: true,
      result: respData.data.result,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const setCurrentUser = (decoded) => {
  console.log(decoded, "decodeddecodeddecoded");
  return {
    type: "SET_CURRENT_USER",
    payload: decoded,
  };
};

export const getCollection = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getCollection`,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const createCollection = async (data) => {
  try {
    console.log(data, "fffff");
    var formData = new FormData();

    for (let i = 0; i < data.image.length; i++) {
      formData.append("image", data.image[i]);
    }
    formData.append("collName", data.collName);
    formData.append("collDisc", data.collDisc);
    formData.append("Collcate", data.Collcate);
    formData.append("ownerAddr", data.ownerAddr);
    formData.append("collRoyalty", data.collRoyalty);

    // for (let i = 0; i < data.bulkFiles.length; i++) {
    //   formData.append("bulkFiles", data.bulkFiles[i]);
    // }

    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/collCreate`,
      data: formData,
    });
    return {
      data: resp.data,
    };
  } catch (err) {
    console.log(err, "errrrrrrre");
    return {
      error: err.resp,
    };
  }
};

export const uploadIPFSDir = async (data) => {
  try {
    console.log(data, "fffff");
    var formData = new FormData();
    if (data.Image) {
      formData.append("bannerImg", data.Image);
      formData.append("folder", data.folder);
      formData.append("collName", data.collName);
      formData.append("collSymb", data.collSymb);
      formData.append("collDisc", data.collDisc);
      // formData.append("collPrice", data.collPrice);
      formData.append("userAddr", data.userAddr);
      formData.append("newContract", data.newContract);
      formData.append("collRoyalty", data.collRoyalty);
      formData.append("collNft", data.collNft);
      formData.append("files", data.files);
      formData.append("ownerAddr", data.ownerAddr);
      formData.append("Shorturl", data.url);
      formData.append("swapEnable", data.swapEnable);
      formData.append("Collcate", data.Collcate);
      formData.append("Unlockoncepurchased", data.Unlockoncepurchased);
    }

    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/uploadIPFSDir`,
      data: formData,
    });
    return {
      data: resp,
    };
  } catch (err) {
    return {
      error: err.resp,
    };
  }
};

export const createCategory = async (data) => {
  // var formData = new FormData();
  // if (data.Image) {
  //   formData.append("imageCat", data.Image);
  //   formData.append("Category", data.Category);
  // }
  // console.log(formData, "hgbfswfkejwlekr");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/createCategory`,
      data,
    });
    console.log(respData, "hgbfswfkejwlekr");
    return {
      success: respData.data.status,
      message: respData.data.message,
    };
  } catch (err) {
    console.log(err);
    return {
      success: err.response.data.status,
      message: err.response.data.message,
    };
  }
};

export const updateCategory = async (data) => {
  var formData = new FormData();
  if (data.Image != undefined) {
    formData.append("imageCat", data.Image);
    formData.append("Category", data.Category);
    formData.append("id", data.id);
  }
  console.log(formData, "hgbfswfkejwlekr");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/updateCategory`,
      data: data.Image != undefined ? formData : data,
    });
    console.log(respData, "hgbfswfkejwlekr");
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const getCategory = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getCategory`,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const CheckCategory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/CheckCategory`,
      data,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const deleteCategory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/deleteCategory`,
      data,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const ipfsImageHashGet = async (data) => {
  console.log(data, "hgbfswfkejwlekrhgbfswfkejwlekrhgbfswfkejwlekr");
  var formData = new FormData();
  if (data.image) {
    formData.append("ImageNFT", data.image[0]);
    formData.append("tokenID", data.tokenID);
    formData.append("collName", data.name);
    formData.append("owner", data.owner);
    formData.append("category", data.categorySelect);
  }
  console.log(formData, "hgbfswfkejwlekr");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/ipfsImageHashGet`,
      data: formData,
      params: { catagory: data.categorySelect },
    });
    console.log(respData, "hgbfswfkejwlekr");
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.respData.data,
    };
  }
};

export const ipfsMetaDataFn = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/ipfsMetaDataFn`,
      data,
    });
    console.log(respData, "hgbfswfkejwlekr");
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.respData.data,
    };
  }
};

export const getAllNft = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getAllNft`,
      params: { search: data.serach, type: data.type },
    });
    console.log(respData, "hgbfswfkejwlekr");
    return {
      data: respData.data.data,
    };
  } catch (error) {
    return {
      error: error.respData.data,
    };
  }
};

export const TokenAddItemAction = async (data) => {
  try {
    console.log(data, "TokenAddItemAction");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/CreateNFT`,
      data,
    });
    return { data: respData.data };
  } catch (err) {
    console.log(err);
    return {
      error: err.respData.data,
    };
  }
};

export const TokenAddOwnerAction = async (data) => {
  try {
    console.log(data, "TokenAddItemAction");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/TokenAddOwner`,
      data,
    });
    return { data: respData.data };
  } catch (err) {
    console.log(err);
    return {
      error: err.respData.data,
    };
  }
};

export const getNFT = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getNFT`,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};
export const getSensitiveNFT = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getSensitiveNFT`,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const getCollNFT = async (data) => {
  console.log(data, "edfsdefs");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getCollNFT/${data.id}`,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const getUsers = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getUsers`,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const getBiddings = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getBiddings`,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const updateServiceFee = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/adminapi/routesAdmin/updateServiceFee`,
      data,
    });
    return { data: respData.data };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const getServiceFee = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `/adminapi/routesAdmin/getServiceFee`,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const getSubscriberList = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getSubscriberList`,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const deleteSubscriber = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/deleteSubscriber`,
      data,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};
export const sendMailToSubs = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/sendMailToSubs`,
      data,
    });
    console.log(respData, "RespDa---------------------Data");
    return {
      data: respData.data,
    };
  } catch (err) {
    console.log(err, "errNewsLetter");
  }
};

export const getTerm = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getTerm`,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};
export const updateTerms = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/updateTerms`,
      data,
    });

    return {
      data: respData.data,
    };
  } catch (err) {
    console.log(err, "errNewsLetter");
  }
};

export const getPolicy = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getPolicy`,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};
export const updatePolicys = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/updatePolicys`,
      data,
    });

    return {
      data: respData.data,
    };
  } catch (err) {
    console.log(err, "errNewsLetter");
  }
};

export const getUserReport = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getUserReport`,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const AddEventPoster = async (payload) => {
  try {
    var formdata = new FormData();
    console.log(payload.image);
    if (payload.image) {
      formdata.append("eventimage", payload.image);
      formdata.append("content", payload.Content);
      formdata.append("date", payload.date);
      formdata.append("title", payload.title);
      console.log(formdata);
      let result = await axios({
        method: "post",
        url: `${config.baseUrl}/adminapi/routesAdmin/addeventposter`,
        data: formdata,
      });
      console.log(result);
      return result.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const checkeventname = async (payload) => {
  try {
    let result = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/checkeventname`,
      data: { payload },
    });
    console.log(result);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const resetPassword = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/resetPassword`,
      data,
    });

    console.log(respData, "data");
    return {
      data: respData.data,
    };
  } catch (err) {
    console.log(err, "errNewsLetter");
  }
};

export const getTransactionDetails = async () => {
  try {
    let responce = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getTransactiondetails`,
    });
    console.log(responce.data, "transactionDetails");
    return responce.data.data;
  } catch (error) {
    console.log(error);
  }
};
//getallevent

export const getEventList = async () => {
  try {
    let responce = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getEventList`,
    });
    console.log(responce.data, "transactionDetails");
    return responce.data.data;
  } catch (error) {
    console.log(error);
  }
};

//deleteEvent

export const DeleteEvent = async (data) => {
  try {
    let responce = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/DeleteEvent`,
      data: { data },
    });
    console.log(responce.data, "transactionDetails");
    return responce.data;
  } catch (error) {
    console.log(error);
  }
};

export const getReport = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getUserReport`,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const getMonthlySalesData = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getMonthlySalesData`,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const getNFTStatisticsdata = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/NFTStatistics`,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const getuserListdata = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/userListdata`,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const checkCollName = async (data) => {
  try {
    const res = await axios({
      url: `${config.baseUrl}/adminapi/routesAdmin/checkCOllname`,
      method: "post",
      data,
    });
    return {
      data: res.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const checkNFTName = async (data) => {
  try {
    const res = await axios({
      url: `${config.baseUrl}/adminapi/routesAdmin/checkNFTname`,
      method: "post",
      data,
    });
    return {
      data: res.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const getSwapHist = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/getSwapHist`,
      data,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const BanData = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/BanData`,
      data,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const UnBanData = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/UnBanData`,
      data,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const getCountDashboard = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getCountDashboard`,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const getAllUser = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getAllUser`,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const getOwnerHistory = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getOwnerHistory`,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};
