// import packages
import axios from "axios";

// import config
import config from "../lib/config";

import isEmpty from "is-empty";
// import utils
// import { getAuthorizationHeader } from "../utils/cookies";
// import { isEmpty } from "../helper/validation";

const instance = axios.create({
    baseURL: config.baseUrl,
    // timeout: 1000,
});

instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem("admin_token");
console.log(token,"tokentoken");
    if (!isEmpty(token)) {
        config.headers.Authorization = token;
    }
    return config;
});



export default instance;
