import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ConditionRoute from "./Route/ConditionRoute";

import Spinner from "../app/shared/Spinner";
import CatagoryList from "./views/categorylist";
// import  from ;
// const NftLists = lazy(() => import('./components/listNft'));

const NftList = lazy(() => import("./views/nftList"));
const Category = lazy(() => import("./views/category"));
const MintNft = lazy(() => import("./views/Mint/mintNft"));

const Dashboard = lazy(() => import("./dashboard/Dashboard"));

const Login = lazy(() => import("./user-pages/Login"));
const AddServiceFee = lazy(() => import("./Settings/addServiceFee"));
const ChangePassword = lazy(() => import("./shared/ChangePass"));

const Userlist = lazy(() => import("./views/userlist"));
const TokenOwner = lazy(() => import("./views/tokenOwner"));
const DefaultNft = lazy(() => import("./views/Mint/defaultNft"));

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <ConditionRoute
            path="/dashboard"
            component={Dashboard}
            type={"private"}
          />
          <ConditionRoute
            path="/settings/add-service-fee"
            component={AddServiceFee}
            type={"private"}
          />
          <ConditionRoute
            path="/NftList"
            component={NftList}
            type={"private"}
          />
          <ConditionRoute
            path="/Category"
            component={Category}
            type={"private"}
          />
          <ConditionRoute
            path="/category_list"
            component={CatagoryList}
            type={"private"}
          />
          <ConditionRoute path="/Mint" component={MintNft} type={"private"} />
          <ConditionRoute
            path="/changepassword"
            component={ChangePassword}
            type={"private"}
          />
          <ConditionRoute
            path="/user-pages/login-1"
            component={Login}
            type={"auth"}
          />
          <ConditionRoute
            path="/user-list"
            component={Userlist}
            type={"private"}
          />{" "}
          <ConditionRoute
            path="/token-Owner"
            component={TokenOwner}
            type={"private"}
          />
            <ConditionRoute
            path="/default-Nft"
            component={DefaultNft}
            type={"private"}
          />



        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
