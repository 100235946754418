import React, { useEffect, useState } from "react";
import { getCategory } from "../actions/admin";
import DataTable from "react-data-table-component";

const CatagoryList = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const data = await getCategory();
      setData(data.data.result, "data");

      console.log(data, "status, result");
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => row.Category_Name,
      width: "200px",
      cell: (row, index) => index + 1,
    },
    {
      name: "category",
      selector: (row, index) => row.Category_Name,
      width: "200px",
    },
    {
      name: "Status",
      selector: (row, index) => row.status,
      width: "200px",
    },
  ];

  return (
    <div>
      <h4 className="card-title">Category List</h4>

      <DataTable
        columns={columns}
        data={data}
        pagination={true}
        paginationPerPage="5"
        paginationRowsPerPageOptions={[5, 10, 15, 20]}
        // subHeader
        // subHeaderComponent={subHeaderComponentMemo}
      />
    </div>
  );
};

export default CatagoryList;
